import Vue from "vue";
import VueRouter from "vue-router";
const Admin = () => import('../views/Admin.vue')
const About = () => import('../views/About.vue')
const Login = () => import('../views/Login.vue')
const Home = () => import('../views/pages/Home/Home.vue')
const Map = () => import('../views/pages/Home/Map.vue')
const Mine = () => import('../views/pages/my/my.vue')
const Public = () => import('../views/pages/my/Public.vue')
const ChangePassword = () => import('../views/pages/my/ChangePassword.vue')
const AccountUpdate = () => import('../views/pages/my/AccountUpdate.vue')
const CompanyAdd = () => import('../views/pages/my/CompanyAdd.vue')
const CompanyUpdate = () => import('../views/pages/my/CompanyUpdate.vue')
const CompanyList = () => import('../views/pages/my/companyList.vue')
const CompanySignList = () => import('../views/pages/my/CompanySignList.vue')
const SignSupervisorAdd = () => import('../views/pages/my/SignSupervisorAdd.vue')
const SignSupervisor = () => import('../views/pages/my/SignSupervisor.vue')
const SignManager = () => import('../views/pages/my/SignManager.vue')
const SignList = () => import('../views/pages/my/signList.vue')
const SignFreezerMsgStatistics = () => import('../views/pages/my/signFreezerMsgStatistics.vue')
const Transfer = () => import('../views/pages/my/Transfer.vue')
const Produce = () => import('../views/pages/Record/Produce/ProduceAdd.vue')
const ProduceDetail = () => import('../views/pages/Record/Produce/ProduceDetail.vue')
const ProduceList = () => import('../views/pages/Record/Produce/List.vue')
const ProduceLog = () => import('../views/pages/Record/Produce/CompanyLog.vue')
const Circulate = () => import('../views/pages/Record/Circulate/CirculateAdd.vue')
const CirculateLog = () => import('../views/pages/Record/Circulate/CompanyLog.vue')
const CirculateList = () => import('../views/pages/Record/Circulate/List.vue')
const CirculateDetail = () => import('../views/pages/Record/Circulate/CirculateDetail.vue')
const Restaurant = () => import('../views/pages/Record/Restaurant/RestaurantAdd.vue')
const RestaurantLog = () => import('../views/pages/Record/Restaurant/CompanyLog.vue')
const RestaurantList = () => import('../views/pages/Record/Restaurant/List.vue')
const RestaurantDetail = () => import('../views/pages/Record/Restaurant/RestaurantDetail.vue')
const ServiceDelivery = () => import('../views/pages/Record/ServiceDelivery/ServiceAdd.vue')
const ServiceDetail = () => import('../views/pages/Record/ServiceDelivery/ServiceDetail.vue')
const ServiceList = () => import('../views/pages/Record/ServiceDelivery/List.vue')
const ServiceLog = () => import('../views/pages/Record/ServiceDelivery/CompanyLog.vue')
const Test = () => import('../views/pages/Record/TestUnit/TestAdd.vue')
const TestDetail = () => import('../views/pages/Record/TestUnit/TestDetail.vue')
const TestList = () => import('../views/pages/Record/TestUnit/List.vue')
const TestLog = () => import('../views/pages/Record/TestUnit/CompanyLog.vue')
const Farm = () => import('../views/pages/Record/FarmProduce/FarmAdd.vue')
const FarmDetail = () => import('../views/pages/Record/FarmProduce/FarmDetail.vue')
const FarmList = () => import('../views/pages/Record/FarmProduce/List.vue')
const FarmLog = () => import('../views/pages/Record/FarmProduce/CompanyLog.vue')
const MenuList = () => import('../views/pages/MenuPermissions/List.vue')
const RoleList = () => import('../views/pages/MenuPermissions/RoleList.vue')
const CompanyRoleList = () => import('../views/pages/MenuPermissions/appMenuPermissions/RoleList.vue')
const RoleAdd = () => import('../views/pages/MenuPermissions/RoleAdd.vue')
const CompanyRoleAdd = () => import('../views/pages/MenuPermissions/appMenuPermissions/RoleAdd.vue')
const RoleuserAdd = () => import('../views/pages/MenuPermissions/RoleuserAdd.vue')
const RoleCompanyAdd = () => import('../views/pages/MenuPermissions/appMenuPermissions/RoleCompanyAdd.vue')
const MenuAdd = () => import('../views/pages/MenuPermissions/Add.vue')
const SuperviseList = () => import('../views/pages/Supervise/List.vue')
const TagList = () => import('../views/pages/Supervise/TagList.vue')
const TagAdd = () => import('../views/pages/Supervise/TagAdd.vue')
const DictionaryList = () => import('../views/pages/Supervise/DictionaryList.vue')
const DictionaryAdd = () => import('../views/pages/Supervise/DictionaryAdd.vue')
const SuperviseAdd = () => import('../views/pages/Supervise/Add.vue')
const AreaAdd = () => import('../views/pages/AreaManage/Add.vue')
const AreaName = () => import('../views/pages/AreaManage/ChangeName.vue')
const AreaBelong = () => import('../views/pages/AreaManage/ChangeBelong.vue')
const AreaDelete = () => import('../views/pages/AreaManage/Delete.vue')
const Employees = () => import('../views/pages/Employees/List.vue')
const Patrol = () => import('../views/pages/Patrol/List.vue')
const PatrolDetail = () => import('../views/pages/Patrol/PatrolDetail.vue')
const AddDetail = () => import('../views/pages/Patrol/AddDetail.vue')
const PatrolAdd = () => import('../views/pages/Patrol/PatrolAdd.vue')
const DoubleRandom = () => import('../views/pages/Patrol/DoubleRandom.vue')
const WorkOrderDetail = () => import('../views/pages/Patrol/WorkOrderDetail.vue')
const WorkOrder = () => import('../views/pages/Patrol/WorkOrder.vue')
const Dispose = () => import('../views/pages/Patrol/Dispose.vue')
const Punish = () => import('../views/pages/Patrol/Punish.vue')
const DisposeAdd = () => import('../views/pages/Patrol/DisposeAdd.vue')
const PunishAdd = () => import('../views/pages/Patrol/PunishAdd.vue')
const CheckSelf = () => import('../views/pages/Checkself/List.vue')
const ScCheckSelf = () => import('../views/pages/Checkself/ScList.vue')
const CheckSelfAdd = () => import('../views/pages/Checkself/Add.vue')
const CheckSelfDetail = () => import('../views/pages/Checkself/Detail.vue')
const ModuleList = () => import('../views/pages/TemplateConfig/ModuleList.vue')
const ModuleAdd = () => import('../views/pages/TemplateConfig/ModuleAdd.vue')
const AttrList = () => import('../views/pages/TemplateConfig/Attr.vue')
const AttrAdd = () => import('../views/pages/TemplateConfig/AttrAdd.vue')
const TemplateList = () => import('../views/pages/TemplateConfig/TemplateList.vue')
const TemplateAdd = () => import('../views/pages/TemplateConfig/TemplateAdd.vue')
const PageRegion = () => import('../views/pages/TemplateConfig/PageRegion.vue')
const platform = () => import('../views/pages/TemplateConfig/platform.vue')
const PageRegionAdd = () => import('../views/pages/TemplateConfig/PageRegionAdd.vue')
const UserList = () => import('../views/pages/Employees/UserList.vue')
const UserList1 = () => import('../views/pages/Employees/UserList1.vue')
const UserListAdd = () => import('../views/pages/Employees/UserListAdd.vue')
const UserListAdd1 = () => import('../views/pages/Employees/UserListAdd1.vue')
const Sample = () => import('../views/pages/Sample/List.vue')
const SampleAdd = () => import('../views/pages/Sample/Add.vue')
const FarmDepartment = () => import('../views/pages/Department/FarmList.vue')
const Department = () => import('../views/pages/Department/List.vue')
const DepartmentAdd = () => import('../views/pages/Department/Add.vue')
const Supervisor = () => import('../views/pages/Department/Supervisor.vue')
const SupervisorAdd = () => import('../views/pages/Department/SupervisorAdd.vue')
const SetMenuList = () => import('../views/pages/Menu/List.vue')
const AppMenuList = () => import('../views/pages/Menu/appMenu/List')
const AppMenuAdd = () => import('../views/pages/Menu/appMenu/Add')
const SetMenuAdd = () => import('../views/pages/Menu/Add.vue')
const SetMenuListTwo = () => import('../views/pages/Menu/ListTwo.vue')
const SetMenuTwoAdd = () => import('../views/pages/Menu/ListTwoAdd.vue')
const BtnList = () => import('../views/pages/Menu/BtnList.vue')
const BtnAdd = () => import('../views/pages/Menu/BtnAdd.vue')
const RecordStatistics = () => import('../views/pages/Statistics/Record.vue')
const CompanyStatistics = () => import('../views/pages/Statistics/Company.vue')
const ManagerStatistics = () => import('../views/pages/Statistics/Manager.vue')
const ListStatistics = () => import('../views/pages/Statistics/List.vue')
const ImportedColdChainGoodsStatistics = () => import('../views/pages/Statistics/ImportedColdChainGoods.vue')
const OnChainEnterpriseInformation = () => import('../views/pages/Statistics/OnChainEnterpriseInformation.vue')
const CorUnitList = () => import('../views/pages/Statistics/cor-unit-list.vue')
const FreezingInfoData = () => import('../views/pages/Statistics/freezing-info-data.vue')
const FreezerNumStatistics = () => import('../views/pages/FreezerStatistics/freezerNumStatistics.vue')
const FreezerMsgStatistics = () => import('../views/pages/FreezerStatistics/freezerMsgStatistics.vue')
const FreezerMsgTotalStatistics = () => import('../views/pages/FreezerStatistics/freezerMsgTotalStatistics.vue')
const FreezerMsgPartStatistics = () => import('../views/pages/FreezerStatistics/freezerMsgPartStatistics.vue')
const StatisticsOfImportEntities = () => import('../views/pages/RegulatoryInformationStatistics/statisticsOfImportEntities.vue')
const OnChainInformationStatistics = () => import('../views/pages/RegulatoryInformationStatistics/onChainInformationStatistics.vue')
const FirstStopStatistics = () => import('../views/pages/RegulatoryInformationStatistics/firstStopStatistics.vue')
const FoodTypeStatistics = () => import('../views/pages/RegulatoryInformationStatistics/FoodTypeStatistics.vue')
const OnChainRegistrationStatus = () => import('../views/pages/RegulatoryInformationStatistics/onChainRegistrationStatus.vue')
const SystemList = () => import('../views/pages/TemplateConfig/System/List.vue')
const SystemAdd = () => import('../views/pages/TemplateConfig/System/Add.vue')

const AreaBusiness = () => import('../views/pages/DepGragh/AreaBusiness.vue')
const orderUpdate = () => import('../views/pages/DepGragh/orderUpdate.vue')

const BelowCompany = () => import('../views/pages/DepGragh/BelowCompany.vue')
const AreaBusinessSign = () => import('../views/pages/DepGragh/AreaBusinessSign.vue')
const Score = () => import('../views/pages/DepGragh/score.vue')
const Entrust = () => import('../views/pages/DepGragh/Entrust.vue')


const GoodsTrace = () => import('../views/pages/DepGragh/GoodsTrace.vue')
const SensorTrace = () => import('../views/pages/DepGragh/SensorTrace.vue')

const GoodsFlow = () => import('../views/pages/DepGragh/GoodsFlow.vue')
const EntrustFlow = () => import('../views/pages/DepGragh/EntrustFlow.vue')

const FullChainTracing = () => import('../views/pages/DepGragh/FullChainTracing.vue')
const FarmGoodsTrace = () => import('../views/pages/DepGragh/FarmGoodsTrace.vue')
const FarmGoodsFlow = () => import('../views/pages/DepGragh/FarmGoodsFlow.vue')
const Screen = () => import('../views/pages/DepGragh/Screen.vue')
const JKScreen = () => import('../views/pages/DepGragh/JKScreen.vue')
const PlantingAreaList = () => import('../views/pages/DepGragh/PlantingAreaList.vue')
const PlantingCategoryList = () => import('../views/pages/DepGragh/PlantingCategoryList.vue')

const CompanyExcel = () => import('../views/pages/CompanyExcel/CompanyExcel.vue')
const CarList = () => import('../views/pages/CarManage/CarList.vue')
const CarListAdd = () => import('../views/pages/CarManage/CarListAdd.vue')
const GoodsList = () => import('../views/pages/GoodsManage/GoodsList.vue')
const BatchDetail = () => import('../views/pages/GoodsManage/BatchDetail.vue')
const SupplyList = () => import('../views/pages/SupplyCompany/SupplyList.vue')
const FrozenGoodsDeclaration = () => import('../views/pages/frozenGoodsDeclaration/frozenGoodsDeclaration.vue')
const Shipping = () => import('../views/pages/Shipping/Shipping.vue')
const ShippingAdd = () => import('../views/pages/Shipping/ShippingAdd.vue')
const Receipt = () => import('../views/pages/Receipt/Receipt.vue')
const WarehouseList = () => import('../views/pages/WarehouseManage/WarehouseList.vue')
const WarehouseListAdd = () => import('../views/pages/WarehouseManage/WarehouseAdd.vue')
const Third = () => import("../views/pages/third/third.vue")
const Emphasis = () => import("../views/pages/third/emphasis.vue")
const Excel = () => import('../views/pages/excel/excel.vue')
const Table1 = () => import('../views/pages/DepGragh/PlantingAreaList.vue')
const Table2 = () => import('../views/pages/DepGragh/PlantingCategoryList.vue')
const FarmStatistics = () => import('../views/pages/DepGragh/FarmStatistics.vue')
const SettingNewArea = () => import("../views/pages/AreaManage/settingNewArea.vue")
const TemperatureSensor = () => import("../views/pages/EquipmentConfig/temperatureSensor")
const SensorMolde = () => import("../views/pages/EquipmentConfig/SensorMolde")
const SensorAlgorithm = () => import("../views/pages/EquipmentConfig/SensorAlgorithm")
const EnterpriseMerchant = () => import("../views/pages/EquipmentConfig/enterpriseMerchant")
const QrcodeManege = () => import("../views/pages/EquipmentConfig/qrcodeManage")
const CompanyScreen = () => import("../views/pages/DepGragh/CompanyScreen")
const energyConsumption = () => import("../views/pages/DepGragh/energyConsumption")

const SensorZhouShanScreen = () => import("../views/pages/DepGragh/sensorZhouShanScreen")

const SensorFuSunScreen = () => import("../views/pages/DepGragh/sensorFuSunScreen")
const SensorPingWuScreen = () => import("../views/pages/DepGragh/sensorPingWuScreen")
const SensorDanLingScreen = () => import("../views/pages/DepGragh/sensorDanLingScreen")
const Stock = () => import("../views/pages/iot/Stock")


const CompanyManagementScreen = () => import("../views/pages/DepGragh/CompanyManagementScreen")
const CamList = () => import("../views/pages/Cam/CamList")
const SwitchList = () => import("../views/pages/Cam/SwitchList")
const SpeakerList = () => import("../views/pages/Cam/SpeakerList")
const SensorList = () => import("../views/pages/Cam/SensorList")
const Haccp = () => import("../views/pages/Haccp/Haccp")
const FarmRecords = () => import("../views/pages/Haccp/FarmRecords")
const ControlScreen = () => import("../views/pages/control_screen/index")
const MaterialScreen = () => import("../views/pages/material_screen/index")
const SensorScreen = () => import("../views/pages/sensor_screen/index")
const EvaluateList = () => import("../views/pages/evaluate/List")
const EvaluateListCode = () => import("../views/pages/evaluate/ListCode")
const PollList = () => import("../views/pages/poll/poll-list")
const Poll = () => import("../views/pages/poll/poll")
const EvaluateChart = () => import("../views/pages/evaluate/EvaluateChart")
const EvaluateChartCode = () => import("../views/pages/evaluate/EvaluateChartCode")
const Audit = () => import("../views/pages/evaluate/Audit")
const AuditCode = () => import("../views/pages/evaluate/AuditCode")
const ShadowCompany = () => import("../views/pages/evaluate/ShadowCompany")
const SafetyRisk = () => import("../views/pages/evaluate/SafetyRisk")
const Kpi = () => import("../views/pages/kpi/kpi")
const CodeAnalysis = () => import("../views/pages/CodeAnalysis/index")
const CompanyMember = () => import("../views/pages/Company/CompanyMember")
const Wallet = () => import("../views/pages/yxms/wallet")
const Order = () => import("../views/pages/yxms/order")
const product = () => import("../views/pages/product/product")
const reimbursementManage = () => import("../views/pages/reimbursement-manage")
const projectManage = () => import("../views/pages/project-manage")
const projectList = () => import("../views/pages/project-manage/project-list")
const taskList = () => import("../views/pages/project-manage/task-list")
const CcpPoint = () => import("../views/pages/Haccp/CcpPoint ")
const DragDetail = () => import("../views/pages/drag/DragDetail.vue")
const DragManagement = () => import("../views/pages/drag/DragManagement.vue")
const ConsultList = () => import("../views/pages/consult/ConsultList.vue")
const ConsultDetail = () => import("../views/pages/consult/ConsultDetail.vue")
const SelectionSetting = () => import("../views/pages/SelectionSetting/index.vue")
const ModeList = () => import("../views/pages/iot/ModeList.vue")
const listCompany = () => import("../views/pages/iot/listCompany.vue")
const ModeManage = () => import("../views/pages/iot/ModeManage.vue")
const NoseGropu = () => import("../views/pages/iot/noseGroup.vue")
const NoseList = () => import("../views/pages/iot/noseList.vue")
const ContractManage = () => import("../views/pages/contract/index.vue")
const ContractList = () => import("../views/pages/contract/list.vue")
const ContractPreview = () => import("../views/pages/contract/preview.vue")



Vue.use(VueRouter);
export default new VueRouter({
  routes: [{
    path: "/",
    redirect: "/login"
  },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/admin",
    redirect: {
      name: "admin"
    }
  }, {
    path: "/about",
    name: "about",
    component: About,

  },
  {
    path: "/admin",
    name: "admin",
    component: Admin,
    children: [{
      path: "home",
      name: "home",
      component: Home
    }, {
      path: "map",
      name: "map",
      component: Map
    },
    {
      path: "mine",
      name: "Mine",
      component: Mine
    }, {
      path: "public",
      name: "Public",
      component: Public
    }, {
      path: "changePassword",
      name: "ChangePassword",
      component: ChangePassword
    }, {
      path: "accountUpdate",
      name: "AccountUpdate",
      component: AccountUpdate
    }, {
      path: "transfer",
      name: "Transfer",
      component: Transfer
    }, {
      path: "companyAdd",
      name: "CompanyAdd",
      component: CompanyAdd
    }, {
      path: "companyList",
      name: "CompanyList",
      component: CompanyList
    }, {
      path: "companySignList",
      name: "CompanySignList",
      component: CompanySignList
    }, {
      path: "signSupervisorAdd",
      name: "SignSupervisorAdd",
      component: SignSupervisorAdd
    }, {
      path: "signSupervisor",
      name: "SignSupervisor",
      component: SignSupervisor
    }, {
      path: "signManager",
      name: "SignManager",
      component: SignManager
    }, {
      path: "signList",
      name: "SignList",
      component: SignList
    }, {
      path: "signFreezerMsgStatistics",
      name: "SignFreezerMsgStatistics",
      component: SignFreezerMsgStatistics
    }, {
      path: "companyUpdate",
      name: "CompanyUpdate",
      component: CompanyUpdate
    }, {
      path: 'produceList',
      name: "ProduceList",
      component: ProduceList
    }, {
      path: 'produce',
      name: "Produce",
      component: Produce
    }, {
      path: 'produceList/detail',
      name: "ProduceDetail",
      component: ProduceDetail
    }, {
      path: 'produceList/produceLog',
      name: "ProduceLog",
      component: ProduceLog
    }, {
      path: "circulate",
      name: "Circulate",
      component: Circulate
    }, {
      path: 'circulateList/detail',
      name: "CirculateDetail",
      component: CirculateDetail
    }, {
      path: "circulateList",
      name: "CirculateList",
      component: CirculateList
    }, {
      path: 'circulateList/circulateLog',
      name: "CirculateLog",
      component: CirculateLog
    }, {
      path: "restaurant",
      name: "Restaurant",
      component: Restaurant
    }, {
      path: 'restaurantList/detail',
      name: "RestaurantDetail",
      component: RestaurantDetail
    }, {
      path: 'restaurantList/restaurantLog',
      name: "RestaurantLog",
      component: RestaurantLog
    }, {
      path: "restaurantList",
      name: "RestaurantList",
      component: RestaurantList
    }, {
      path: "serviceDelivery",
      name: "ServiceDelivery",
      component: ServiceDelivery
    }, {
      path: 'serviceList',
      name: "ServiceList",
      component: ServiceList
    }, {
      path: 'serviceList/detail',
      name: "ServiceDetail",
      component: ServiceDetail
    }, {
      path: 'serviceList/serviceLog',
      name: "ServiceLog",
      component: ServiceLog
    }, {
      path: "test",
      name: "Test",
      component: Test
    }, {
      path: 'testList/detail',
      name: "TestDetail",
      component: TestDetail
    }, {
      path: 'testList',
      name: "TestList",
      component: TestList
    }, {
      path: 'testList/testLog',
      name: "TestLog",
      component: TestLog
    }, {
      path: "farm",
      name: "Farm",
      component: Farm
    }, {
      path: 'farmList',
      name: "FarmList",
      component: FarmList
    }, {
      path: 'farmList/detail',
      name: "FarmDetail",
      component: FarmDetail
    }, {
      path: 'farmList/farmLog',
      name: "FarmLog",
      component: FarmLog
    }, {
      path: "menuList",
      name: "MenuList",
      component: MenuList
    }, {
      path: "menuList/add",
      name: "MenuAdd",
      component: MenuAdd
    }, {
      path: "roleList",
      name: "RoleList",
      component: RoleList
    }, {
      path: "companyRoleList",
      name: "CompanyRoleList",
      component: CompanyRoleList
    }, {
      path: "roleCompanyAdd",
      name: "RoleCompanyAdd",
      component: RoleCompanyAdd
    }, {
      path: "roleList/add",
      name: "RoleAdd",
      component: RoleAdd
    }, {
      path: "companyRoleAdd",
      name: "CompanyRoleAdd",
      component: CompanyRoleAdd
    }, {
      path: "roleList/userAdd",
      name: "RoleuserAdd",
      component: RoleuserAdd
    }, {
      path: "supervise",
      name: "SuperviseList",
      component: SuperviseList
    }, {
      path: "tag",
      name: "TagList",
      component: TagList
    }, {
      path: "dictionary",
      name: "DictionaryList",
      component: DictionaryList
    }, {
      path: "dictionary/add",
      name: "DictionaryAdd",
      component: DictionaryAdd
    }, {
      path: "supervise/add",
      name: "SuperviseAdd",
      component: SuperviseAdd
    }, {
      path: "tag/add",
      name: "TagAdd",
      component: TagAdd
    }, {
      path: "areaAdd",
      name: "AreaAdd",
      component: AreaAdd
    }, {
      path: "areaName",
      name: "AreaName",
      component: AreaName
    }, {
      path: "areaBelong",
      name: "AreaBelong",
      component: AreaBelong
    }, {
      path: "settingNewArea",
      name: "SettingNewArea",
      component: SettingNewArea
    }, {
      path: "areaDelete",
      name: "AreaDelete",
      component: AreaDelete
    }, {
      path: "user",
      name: "Employees",
      component: Employees
    }, {
      path: "patrol",
      name: "Patrol",
      component: Patrol
    }, {
      path: "doubleRandom",
      name: "DoubleRandom",
      component: DoubleRandom
    }, {
      path: "workOrder",
      name: "WorkOrder",
      component: WorkOrder
    }, {
      path: "doubleRandom/detail",
      name: "WorkOrderDetail",
      component: WorkOrderDetail
    }, {
      path: "patrol/detail",
      name: "PatrolDetail",
      component: PatrolDetail
    }, {
      path: "patrol/add",
      name: "PatrolAdd",
      component: PatrolAdd
    }, {
      path: "patrol/addDetail",
      name: "AddDetail",
      component: AddDetail
    }, {
      path: "dispose",
      name: "Dispose",
      component: Dispose
    }, {
      path: "punish",
      name: "Punish",
      component: Punish
    }, {
      path: "dispose/add",
      name: "DisposeAdd",
      component: DisposeAdd
    }, {
      path: "punish/add",
      name: "PunishAdd",
      component: PunishAdd
    }, {
      path: "checkself",
      name: "CheckSelf",
      component: CheckSelf
    }, {
      path: "checkself/add",
      name: "CheckSelfAdd",
      component: CheckSelfAdd
    }, {
      path: "checkself/detail",
      name: "CheckSelfDetail",
      component: CheckSelfDetail
    }, {
      path: "scCheckSelf",
      name: "ScCheckSelf",
      component: ScCheckSelf
    }, {
      path: "pageModule",
      name: "ModuleList",
      component: ModuleList
    }, {
      path: "pageModule/add",
      name: "ModuleAdd",
      component: ModuleAdd
    }, {
      path: "pageModule/pageAttr",
      name: "AttrList",
      component: AttrList
    }, {
      path: "pageModule/pageAttr/add",
      name: "AttrAdd",
      component: AttrAdd
    }, {
      path: "template",
      name: "TemplateList",
      component: TemplateList
    }, {
      path: "template/add",
      name: "TemplateAdd",
      component: TemplateAdd
    }, {
      path: "pageConfigRegion",
      name: "PageRegion",
      component: PageRegion
    }, {
      path: "platform",
      name: "platform",
      component: platform
    },
    // {
    //   path: "pageConfigRegion",
    //   name: "PageRegion",
    //   component: PageRegion
    // }, 
    {
      path: "pageConfigRegion/add",
      name: "PageRegionAdd",
      component: PageRegionAdd
    }, {
      path: "userList",
      name: "UserList",
      component: UserList
    }, {
      path: "userList1",
      name: "UserList1",
      component: UserList1
    }, {
      path: "userList/add",
      name: "UserListAdd",
      component: UserListAdd
    }, {
      path: "userList/add1",
      name: "UserListAdd1",
      component: UserListAdd1
    }, {
      path: "sample",
      name: "Sample",
      component: Sample
    }, {
      path: "sample/add",
      name: "SampleAdd",
      component: SampleAdd
    }, {
      path: "department",
      name: "Department",
      component: Department
    }, {
      path: "farmDepartment",
      name: "FarmDepartment",
      component: FarmDepartment
    }, {
      path: "department/add",
      name: "DepartmentAdd",
      component: DepartmentAdd
    }, {
      path: "department/supervisor",
      name: "Supervisor",
      component: Supervisor
    }, {
      path: "department/supervisorAdd",
      name: "SupervisorAdd",
      component: SupervisorAdd
    }, {
      path: "menu",
      name: 'SetMenuList',
      component: SetMenuList
    }, {
      path: "appMenu",
      name: 'AppMenuList',
      component: AppMenuList
    }, {
      path: "appMenu/add",
      name: 'AppMenuAdd',
      component: AppMenuAdd
    }, {
      path: "menu/add",
      name: 'SetMenuAdd',
      component: SetMenuAdd
    }, {
      path: "menuTwo",
      name: 'SetMenuListTwo',
      component: SetMenuListTwo
    }, {
      path: "menuTwo/add",
      name: 'SetMenuTwoAdd',
      component: SetMenuTwoAdd
    }, {
      path: "menubtnList",
      name: 'BtnList',
      component: BtnList
    }, {
      path: "menubtnList/add",
      name: 'BtnAdd',
      component: BtnAdd
    }, {
      path: "recordStatistics",
      name: 'RecordStatistics',
      component: RecordStatistics
    }, {
      path: "companyStatistics",
      name: 'CompanyStatistics',
      component: CompanyStatistics
    }, {
      path: "managerStatistics",
      name: 'ManagerStatistics',
      component: ManagerStatistics
    }, {
      path: "listStatistics",
      name: 'ListStatistics',
      component: ListStatistics
    }, {
      path: "importedColdChainGoodsStatistics",
      name: 'ImportedColdChainGoodsStatistics',
      component: ImportedColdChainGoodsStatistics
    }, {
      path: "onChainEnterpriseInformation",
      name: 'OnChainEnterpriseInformation',
      component: OnChainEnterpriseInformation
    }, {
      path: "corUnitList",
      name: 'CorUnitList',
      component: CorUnitList
    }, {
      path: "freezingInfoData",
      name: 'FreezingInfoData',
      component: FreezingInfoData
    }, {
      path: "freezerNumStatistics",
      name: 'FreezerNumStatistics',
      component: FreezerNumStatistics
    }, {
      path: "freezerMsgStatistics",
      name: 'FreezerMsgStatistics',
      component: FreezerMsgStatistics
    }, {
      path: "freezerMsgTotalStatistics",
      name: 'FreezerMsgTotalStatistics',
      component: FreezerMsgTotalStatistics
    }, {
      path: "freezerMsgPartStatistics",
      name: 'FreezerMsgPartStatistics',
      component: FreezerMsgPartStatistics
    },
    {
      path: "statisticsOfImportEntities",
      name: 'StatisticsOfImportEntities',
      component: StatisticsOfImportEntities
    }, {
      path: "onChainInformationStatistics",
      name: 'OnChainInformationStatistics',
      component: OnChainInformationStatistics
    }, {
      path: "foodTypeStatistics",
      name: 'FoodTypeStatistics',
      component: FoodTypeStatistics
    },
    {
      path: "firstStopStatistics",
      name: 'FirstStopStatistics',
      component: FirstStopStatistics
    }, {
      path: "onChainRegistrationStatus",
      name: 'OnChainRegistrationStatus',
      component: OnChainRegistrationStatus
    }, {
      path: "system",
      name: 'SystemList',
      component: SystemList
    },
    {
      path: "system/add",
      name: 'SystemAdd',
      component: SystemAdd
    },
    {
      path: "belowCompany",
      name: 'BelowCompany',
      component: BelowCompany
    }, {
      path: "areaBusiness",
      name: 'AreaBusiness',
      component: AreaBusiness
    }, {
      path: "entrust",
      name: 'Entrust',
      component: Entrust
    }, {
      path: "areaBusinessSign",
      name: 'AreaBusinessSign',
      component: AreaBusinessSign
    }, {
      path: "orderUpdate",
      name: 'orderUpdate',
      component: orderUpdate
    }, {
      path: "score",
      name: 'Score',
      component: Score
    }, {
      path: "goodsTrace",
      name: 'GoodsTrace',
      component: GoodsTrace
    }, {
      path: "sensorTrace",
      name: 'sensorTrace',
      component: SensorTrace
    }, {
      path: "goodsFlow",
      name: 'GoodsFlow',
      component: GoodsFlow
    }, {
      path: "entrust-flow",
      name: 'EntrustFlow',
      component: EntrustFlow
    }, {
      path: "farmGoodsTrace",
      name: 'FarmGoodsTrace',
      component: FarmGoodsTrace
    }, {
      path: "fullChainTracing",
      name: 'FullChainTracing',
      component: FullChainTracing
    }, {
      path: "farmGoodsFlow",
      name: 'FarmGoodsFlow',
      component: FarmGoodsFlow
    }, {
      path: "farmStatistics",
      name: 'FarmStatistics',
      component: FarmStatistics
    },
    {
      path: "screen",
      name: 'Screen',
      component: Screen
    },
    // {
    //   path: "goodsTrace",
    //   name: 'GoodsTrace',
    //   component: GoodsTrace
    // },
    // {
    //   path: "goodsFlow",
    //   name: 'GoodsFlow',
    //   component: GoodsFlow
    // },
    {
      path: "energyConsumption",
      name: 'energyConsumption',
      component: energyConsumption
    },
    {
      path: "companyScreen",
      name: 'CompanyScreen',
      component: CompanyScreen
    }, {
      path: "sensorFuSunScreen",
      name: 'sensorFuSunScreen',
      component: SensorFuSunScreen
    }, {
      path: "sensorZhouShanScreen",
      name: 'sensorZhouShanScreen',
      component: SensorZhouShanScreen
    },
    {
      path: "sensorPingWuScreen",
      name: 'sensorPingWuScreen',
      component: SensorPingWuScreen
    }, {
      path: "sensorDanLingScreen",
      name: 'sensorDanLingScreen',
      component: SensorDanLingScreen

    },{
      path: "stock",
      name: 'stock',
      component: Stock
    },
    {
      path: "companyManagementScreen",
      name: 'CompanyManagementScreen',
      component: CompanyManagementScreen
    },
    // {
    //   path: "screen",
    //   name: 'Screen',
    //   component: Screen
    // },
    {
      path: "jkscreen",
      name: 'JKScreen',
      component: JKScreen
    },
    {
      path: "plantingAreaList",
      name: 'PlantingAreaList',
      component: PlantingAreaList
    }, {
      path: "plantingCategoryList",
      name: 'PlantingCategoryList',
      component: PlantingCategoryList
    },
    {
      path: "carList",
      name: 'CarList',
      component: CarList
    },
    {
      path: "carList/add",
      name: 'CarListAdd',
      component: CarListAdd
    },
    {
      path: "goodsList",
      name: 'GoodsList',
      component: GoodsList
    },
    {
      path: "goodsList/batchDetail",
      name: 'batchDetail',
      component: BatchDetail
    },
    {
      path: "warehouseList",
      name: 'WarehouseList',
      component: WarehouseList
    },
    {
      path: "warehouseList/add",
      name: 'WarehouseListAdd',
      component: WarehouseListAdd
    }, {
      path: "supplyList",
      name: 'SupplyList',
      component: SupplyList
    }, {
      path: "frozenGoodsDeclaration",
      name: 'FrozenGoodsDeclaration',
      component: FrozenGoodsDeclaration
    }, {
      path: "shipping",
      name: 'Shipping',
      component: Shipping
    }, {
      path: "shippingAdd",
      name: 'ShippingAdd',
      component: ShippingAdd
    }, {
      path: "receipt",
      name: 'Receipt',
      component: Receipt
    },
    {
      path: "excel",
      name: 'Excel',
      component: Excel
    },
    {
      path: "table1",
      name: 'Table1',
      component: Table1
    },
    {
      path: "table2",
      name: 'Table2',
      component: Table2
    },
    // {
    //   path: "farmStatistics",
    //   name: 'FarmStatistics',
    //   component: FarmStatistics
    // },
    {
      path: "third",
      name: 'Third',
      component: Third
    }, {
      path: "emphasis",
      name: 'Emphasis',
      component: Emphasis
    }, {
      path: "temperatureSensor",
      name: 'TemperatureSensor',
      component: TemperatureSensor
    }, {
      path: "sensorMolde",
      name: 'SensorMolde',
      component: SensorMolde
    }, {
      path: "sensorAlgorithm",
      name: 'sensorAlgorithm',
      component: SensorAlgorithm
    }, {
      path: "enterpriseMerchant",
      name: 'EnterpriseMerchant',
      component: EnterpriseMerchant
    }, {
      path: "qrcodeManage",
      name: 'QrcodeManege',
      component: QrcodeManege
    }, {
      path: "camList",
      name: 'camList',
      component: CamList
    }, {
      path: "switchList",
      name: 'switchList',
      component: SwitchList
    }, {
      path: "speakerList",
      name: 'speakerList',
      component: SpeakerList
    }, {
      path: "sensorList",
      name: 'sensorList',
      component: SensorList
    }, {
      path: "haccp",
      name: 'haccp',
      component: Haccp
    }, {
      path: "ccp",
      name: 'ccp',
      component: CcpPoint
    }, {
      path: "farmRecords",
      name: 'farmRecords',
      component: FarmRecords
    }, {
      path: "controlScreen",
      name: 'controlScreen',
      component: ControlScreen,
    }, {
      path: "sensorScreen",
      name: 'sensorScreen',
      component: SensorScreen
    },
    {
      path: "materialScreen",
      name: 'materialScreen',
      component: MaterialScreen
    },
    {
      path: "companyExcel",
      name: 'CompanyExcel',
      component: CompanyExcel
    },
    {
      path: "evaluateList",
      name: 'EvaluateList',
      component: EvaluateList
    },
    {
      path: "evaluateListCode",
      name: 'EvaluateListCode',
      component: EvaluateListCode
    },
    {
      path: "poll-list",
      name: 'pollList',
      component: PollList
    },
    {
      path: "poll",
      name: 'poll',
      component: Poll
    },

    {
      path: "evaluateChart",
      name: 'EvaluateChart',
      component: EvaluateChart
    },
    {
      path: "evaluateChartCode",
      name: 'EvaluateChartCode',
      component: EvaluateChartCode
    },
    {
      path: "auditCode",
      name: 'AuditCode',
      component: AuditCode
    },
    {
      path: "audit",
      name: 'Audit',
      component: Audit
    },
    {
      path: "shadowCompany",
      name: 'ShadowCompany',
      component: ShadowCompany
    },
    {
      path: "safetyRisk",
      name: 'SafetyRisk',
      component: SafetyRisk
    },
    {
      path: "codeAnalysis",
      name: 'CodeAnalysis',
      component: CodeAnalysis
    },
    {
      path: "kpi",
      name: 'Kpi',
      component: Kpi
    }, {
      path: "companyMember",
      name: 'CompanyMember',
      component: CompanyMember
    }, {
      path: "wallet",
      name: 'Wallet',
      component: Wallet
    }, {
      path: "order",
      name: 'order',
      component: Order

    }, {
      path: "product",
      name: 'product',
      component: product
    }, {
      path: "reimbursement-manage",
      name: 'reimbursement-manage',
      component: reimbursementManage
    }, {

      path: "project-manage",
      name: 'project-manage',
      component: projectManage
    }, {
      path: "project-list",
      name: 'project-list',
      component: projectList
    }, {
      path: "task-list",
      name: 'task-list',
      component: taskList
    }, {

      path: "drag-detail",
      name: 'drag-detail',
      component: DragDetail
    }, {
      path: "drag-management",
      name: 'drag-management',
      component: DragManagement
    }, {
      path: "consult-list",
      name: "consult-list",
      component: ConsultList
    }, {
      path: "consult-detail",
      name: "consult-detail",
      component: ConsultDetail
    }, {
      path: "selection-setting",
      name: "selection-setting",
      component: SelectionSetting
    }, {
      path: "iot-mode-list",
      name: "iot-mode-list",
      component: ModeList
    }, {
      path: "iot-list-company",
      name: "iot-list-company",
      component: listCompany
    }, {
      path: "iot-mode-manage",
      name: "iot-mode-manage",
      component: ModeManage
    }, {
      path: "nose-group",
      name: "nose-group",
      component: NoseGropu
    }, {
      path: "nose-list",
      name: "nose-list",
      component: NoseList
    }, {
      path: "contract-manage",
      name: "contract-manage",
      component: ContractManage
    }, {
      path: "contract-preview",
      name: "contract-preview",
      component: ContractPreview
    }, {
      path: "contract-list",
      name: "contract-list",
      component: ContractList
    }]
  }
  ]
})
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}
